import service from '@/until/api'


//中心云账号管理---编辑账号

export function _UpdateUserCenter(data) {
    return service({
      url: '/user-nacos/user/updateUser',
      method: 'POST',
      data
    })
  }

  //账号中心---修改密码
  export function _UpdatePasswordForCenter(data) {
    return service({
      url: '/user-nacos/user/updatePasswordForCenter',
      method: 'POST',
      data
    })
  }
  
  
