<template>
    <div class="accCenter">
        <div class="centerForm">
            <el-form ref="form" :model="form" label-width="80px" :rules="rules">
                <el-form-item label="账号名" prop="username">
                    <el-input  disabled v-model="form.username" placeholder="请输入" class="centerCzInput" clearable></el-input>
                </el-form-item>
                <el-form-item label="中文名" prop="name">
                    <el-input v-model="form.name" placeholder="请输入" class="centerCzInput" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input  v-model="form.phone" placeholder="请输入" class="centerCzInput" clearable></el-input>
                </el-form-item>
                <el-form-item label="密码" required>
                    <span>******</span>
                    <img src="../../assets/images/edit.png" alt="" class="editImg">
                    <span class="editSpan" @click="changePwd=true">修改密码</span>
                </el-form-item>

                <el-form-item>
                    <button type="button" class="centerCzbtn" @click="updateAccent">保存</button>
                    <button type="button" class="centerBtn resetbtn ml20" @click="backPage">取消</button>
                </el-form-item>
            </el-form>
        </div>

        <el-dialog title="修改密码" :visible.sync="changePwd" width="25%">
            <el-form ref="form1" :model="formPwd" label-width="80px">
                <el-form-item label="旧密码">
                    <el-input type="password" v-model="formPwd.oldPassword" placeholder="请输入" class="centerCzInput"
                        clearable></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input type="password" v-model="formPwd.newPassword" placeholder="请输入" class="centerCzInput"
                        clearable></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <button class="centerBtn" @click="passCon">确 定</button>
                <button class="centerBtn resetbtn ml20" @click="changePwd = false">取 消</button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import qs from 'qs'
    import {
        _UpdateUserCenter,
        _UpdatePasswordForCenter
    } from '@/api/accCenter'

    import {
        _GetUserInfo
    } from '@/api/login/index'
    export default {
        data() {
            return {
                changePwd: false,
                form: {
                    username: "",
                    name: "",
                    phone: "",
                    id:""
                },
                formPwd: {
                    oldPassword: "",
                    newPassword: "",
                    userId: ""
                },
                rules: {
                    username: [
                        { required: true, message: '请输入账号名', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '请输入中文名', trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        {
                            pattern: /^1[3456789]\d{9}$/,
                            message: '请输入正确的手机号码',
                            trigger: 'blur'
                        }
                    ],
                }
            };
        },
        mounted() {
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))

            this.getUserInfo()
        },
        methods: {
           async updateAccent(){
                this.$refs.form.validate(async(valid) => {
                    if(valid){
                        let {success,data,message}=await _UpdateUserCenter(this.form)
                        if(success){
                            this.$message.success("修改成功")
                            this.getUserInfo()
                        }
                    }
                })
            },
            // 确认修改密码
           async passCon() {
              let reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*,\.])[0-9a-zA-Z!@#$%^&*,\\.]{6,16}$/
              if (!reg.test(this.formPwd.newPassword)) {
                this.$message.error("密码必须包含数字，大写字母，小写字母，符号")
                return false
              }
               let {success,data,message}=await _UpdatePasswordForCenter(qs.stringify(this.formPwd))
               if(success){
                   this.changePwd=false
                   this.$message.success('设置成功')
                    // this.getUserInfo()    
               }
            },
            backPage() {
                this.$router.go(-1)
            },
            // 获取用户信息
            async getUserInfo() {
                let {
                    success,
                    data,
                    message
                } = await _GetUserInfo()
                if (success) {
                    this.form = {
                            name: data.map.name,
                            username: data.map.user_name,
                            phone: data.map.phone,
                            id:data.map.id
                        },
                        this.formPwd.userId = data.map.id
                }
            }

        }
    }
</script>

<style scoped>
    .accCenter {
        background-color: #fff;
        height: 100%;
        display: flex;
    }

    .centerForm {
        margin: 80px;
    }

    .centerForm input {
        width: 360px;
    }

    .editImg {
        vertical-align: text-top;
        margin: 0px 4px 0px 20px;
    }

    .editSpan {
        color: #1683D9;
        font-size: 14px;
        cursor: pointer;
    }
</style>